const apiBase = (window as any).REACT_APP_BACKEND_API_BASE;
const url = apiBase ? apiBase : "http://" + window.location.hostname + ":8080/opk";
const api = url + "/api";

const Endpoint = {
  API: api,
  AUTHENTICATE: url + "/authenticate",
  RESET_PASSWORD: url + "/resetPassword",
  SPONSOR_ORGANIZATION_LIST: api + "/codetable/sponsorOrganizationList",
  ARTICLE_LIST: api + "/article/articleList",
  ARTICLE_AREA: api + "/codetable/activeArticleAreaList",
  ARTICLE_SEARCH: api + "/article/articleListByArticleAreaList",
  WEB_ARTICLE_LIST: api + "/article/webSiteArticleList",
  WEB_EVENT_LIST: api + "/event/eventList",
  WEB_ARTICLE_SEARCH: api + "/article/webSiteArticleListByArticleAreaList",
  ARTICLE_ON_CAROUSEL_LIST: api + "/article/articleOnCarouselList",
  MOST_VISITED_ARTICLE_LIST: api + "/article/mostVisitedArticleList",
  EVENTS: api + "/event/eventList/activeAndFinishedAndCanceled",
  ENUM_STATUS: api + "/codetable/eventStatusList",
  ENUM_DIFFICULTY: api + "/codetable/eventDifficultyList",
  USER: api + "/user",
  HOME_PAGE: api + "/event/eventList/homePage",

  HOME_KM_JAN_JUL_STATISTICS: "https://public.tableau.com/views/StatistikaKM/StatistikaKM",
  HOME_GOAL_STATISTICS: "https://public.tableau.com/views/Statistika_16045787802080/Statistika",
  SAVETOVALISTA: "https://public.tableau.com/views/SavetovalistaBeograd/map",
  TWITTER_CITIESDIABETES: "https://twitter.com/citiesdiabetes",
  OPK_APPLE_STORE: "https://apps.apple.com/us/app/otvori-plavi-krug/id1538660838#?platform=iphone",
  OPK_GOOGLE_STORE: "https://play.google.com/store/apps/details?id=rs.heliant.mobile.opk",
  CONTACT_MAIL: "mailto:info@otvoriplavikrug.rs",
  HELIANT: "https://heliant.rs",
  CITIESCHANGINGDIABETES: "https://www.citieschangingdiabetes.com/",
  TALKABOUTDIABETES: "https://www.diabeteswhatsnext.com/rs/sr.html",
  PRIVACY_POLICY: window.location.protocol + "//" + window.location.hostname + "/sr/privacy-policy.html",
  REGISTER: url + "/register",
  PROTOCOL_HOSTNAME: window.location.protocol + "//" + window.location.hostname,
  OPK_FACEBOOK: "https://www.facebook.com/otvoriplavikrug",
  ACTIVITY_RANGE: api + "/activity/activityRange",
  ARTICLE: api + "/article",
};

export default Endpoint;

import axios from "axios";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, ViberIcon, ViberShareButton, WhatsappIcon, WhatsappShareButton, LinkedinIcon, LinkedinShareButton } from "react-share";
import GridLoader from "react-spinners/GridLoader";
import Endpoint from "../../system/Endpoint";
import Enum from "../../system/Enum";
import { getImage } from "../../system/Utils";
import { Style } from "./ArticleStyle";
import YouTube from "react-youtube";

export default function Article() {
  const { id } = useParams();
  const [articleChange, setArticleChange] = useState({});
  const { tableau } = window;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (articleChange.tableauUrl) {
      var articleTableauDiv = document.getElementById("articleTableauDiv"),
        url = articleChange.tableauUrl,
        options = {
          hideTabs: true,
          onFirstInteractive: function () {},
        };
      new tableau.Viz(articleTableauDiv, url, options);
    }
    if (articleChange.id) {
      axios
        .put(Endpoint.ARTICLE_LIST + `/${articleChange.id}/updateVisitNum`)
        .then(() => {})
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleChange]);

  useEffect(() => {
    id &&
      axios
        .get(Endpoint.WEB_ARTICLE_LIST + `/${id}`)
        .then((responseArticle) => {
          setArticleChange(responseArticle.data.data);
          setLoading(false);
        })
        .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <GridLoader size={50} color={"#2a918b"} loading={loading} css={Style.spinnerStyle} />
      <div>
        <div id="home" className="slider">
          {!loading && articleChange.headerImage && <img className="d-block w-100" src={getImage(false, articleChange.headerImage)} alt="header" />}
        </div>
        <div id="article-ID" className={articleChange.headerImage ? "card" : "card article-margin-top"} style={{ overflow: "hidden" }}>
          {!loading && (
            <div style={Style.socialButtons}>
              <div>
                <WhatsappShareButton title={articleChange.title} url={Endpoint.PROTOCOL_HOSTNAME + "/article/" + articleChange.id}>
                  <WhatsappIcon size={40}></WhatsappIcon>
                </WhatsappShareButton>
                <FacebookShareButton title={articleChange.title} url={Endpoint.PROTOCOL_HOSTNAME + "/article/" + articleChange.id}>
                  <FacebookIcon size={40}></FacebookIcon>
                </FacebookShareButton>
                <TwitterShareButton title={articleChange.title} url={Endpoint.PROTOCOL_HOSTNAME + "/article/" + articleChange.id}>
                  <TwitterIcon size={40}></TwitterIcon>
                </TwitterShareButton>
                <ViberShareButton title={articleChange.title} url={Endpoint.PROTOCOL_HOSTNAME + "/article/" + articleChange.id}>
                  <ViberIcon size={40}></ViberIcon>
                </ViberShareButton>
                <LinkedinShareButton title={articleChange?.title} url={Endpoint.PROTOCOL_HOSTNAME + "/article/" + articleChange.id}>
                  <LinkedinIcon size={40}></LinkedinIcon>
                </LinkedinShareButton>
              </div>
            </div>
          )}
          <p className="mt-3 mb-5" style={Style.heading}>
            {articleChange.title}
          </p>
          {articleChange.articleType === Enum.HTML && <div className="reactHtmlParser">{ReactHtmlParser(articleChange.body)}</div>}
          {articleChange.articleType === Enum.URL && <a href={articleChange.body}>{articleChange.body}</a>}
          {articleChange.articleType === Enum.PLAIN_TEXT && <div>{articleChange.body}</div>}
          {articleChange.articleType === Enum.VIDEO && (
            <div>
              <YouTube videoId={articleChange.youtubeCode} />
            </div>
          )}
          {articleChange.tableauUrl && (
            <div id="spisak-gradova-ID" className="tableau-map-container">
              <div id="articleTableauDiv" className="tableau-map-iframe-container"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import { Route } from "react-router-dom";
import Home from "../Home";
import Login from "../login/Login";
import Registration from "../registration/Registration";
import UserProfile from "../userProfile/UserProfile";
import Article from "../screens/article/Article";
import ArticleListScreen from "../screens/article/ArticleListScreen";
import EventListScreen from "../screens/EventListScreen";
import Event from "../screens/Event";
import Savetovalista from "../screens/Savetovalista";

export default function Router() {
  return (
    <div>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/userProfile/:id" component={UserProfile} />
      <Route path="/registration" component={Registration} />
      <Route path="/articleList/:id" component={ArticleListScreen} />
      <Route path="/article/:id" component={Article} />
      <Route path="/event/:id" component={Event} />
      <Route path="/eventList" component={EventListScreen} />
      <Route path="/savetovalista" component={Savetovalista} />
    </div>
  );
}

import MessageType from "./MessageType";
import commaNumber from "comma-number";
import Labels from "./Labels_sr_Latn_RS";

const axiosConfig = (params) => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const axiosConfiguration = (token, params) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return Labels.LABEL_JANUARY;
    case 2:
      return Labels.LABEL_FEBRUARY;
    case 3:
      return Labels.LABEL_MARCH;
    case 4:
      return Labels.LABEL_APRIL;
    case 5:
      return Labels.LABEL_MAY;
    case 6:
      return Labels.LABEL_JUNE;
    case 7:
      return Labels.LABEL_JULY;
    case 8:
      return Labels.LABEL_AUGUST;
    case 9:
      return Labels.LABEL_SEPTEMBER;
    case 10:
      return Labels.LABEL_OCTOBER;
    case 11:
      return Labels.LABEL_NOVEMBER;
    case 12:
      return Labels.LABEL_DECEMBER;
    default:
      return "";
  }
};

const imageExtenstion = (base64) => {
  switch (base64.charAt(0)) {
    case "/":
      return "jpeg";
    case "i":
      return "png";
    case "R":
      return "gif";
    case "U":
      return "webp";
    default:
      return "";
  }
};

const getImage = (needUri, source, replaceImage) => {
  return source ? (needUri ? { uri: `data:image/${imageExtenstion(source)};base64,${source}` } : `data:image/${imageExtenstion(source)};base64,${source}`) : replaceImage;
};

const numberRepresentation = (value) => {
  return value !== undefined && value !== null ? commaNumber(value.toString().replace(".", ","), ".", ",") : "";
};

const meterToKilometer = (value, decimal) => {
  return Number((value / 1000).toFixed(decimal));
};

const handleAxiosCallSuccess = (showMessage, response) => {
  if (response.data.data.successes) {
    showMessage(
      MessageType.SUCCESS,
      undefined,
      response.data.data.successes.map((e) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.SUCCESS, undefined, response.toString());
  }
};

const handleAxiosCallError = (showMessage, error) => {
  if (error.response.data.errors) {
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e) => e.message + "\n")
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const dummyImageOPK = "/imgs/dummyHeader.jpg";

const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const CARUSEL_LIST_PAGE_SIZE = 6;
const ARTICLE_LIST_PAGE_SIZE = 6;
const EVENTS_LIST_PAGE_SIZE = 6;
const MOST_VISITED_ARTICLE_SIZE = 3;
const EVENTS_HOME_SIZE = 3;
const PAGE = 0;
const NAVBAR_MENU_ACTIVE = 995;
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const INITIAL_LATITUDE = 44.786568;
const INITIAL_LONGITUDE = 20.448922;

export {
  axiosConfig,
  axiosConfiguration,
  imageExtenstion,
  getImage,
  handleAxiosCallSuccess,
  handleAxiosCallError,
  getMonthName,
  DATE_TIME_FORMAT,
  CARUSEL_LIST_PAGE_SIZE,
  PAGE,
  ARTICLE_LIST_PAGE_SIZE,
  EVENTS_LIST_PAGE_SIZE,
  EVENTS_HOME_SIZE,
  MOST_VISITED_ARTICLE_SIZE,
  NAVBAR_MENU_ACTIVE,
  dummyImageOPK,
  CALENDAR_DATE_FORMAT,
  INITIAL_LATITUDE,
  INITIAL_LONGITUDE,
  meterToKilometer,
  numberRepresentation,
};

const Labels = {
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  DASH: " - ",
  SLASH: "/",
  // Special strings
  SPECIAL_CHAR_REQUIRED: " *",

  // Application
  APPLICATION_NAME: "otvori plavi krug",

  TO_REALIZATION: "Koliko još do donacije (rok 31.06.2021. godine)",
  MOST_VISITED: "Najčitanije",
  PROJECT_PARTNERS: "Partneri projekta",
  CONTACT: "Kontakt",
  OPK_MAIL: "info@otvoriplavikrug.rs",
  PROJECT: "Projekat",
  ARTICLE_LIST: "Članci",
  ABOUT_PROJECT: "O projektu",
  CITIES_CHANGIN_DIABETES: "Gradovi koji menjaju dijabetes",
  FAQ: "Često postavljana pitanja",
  MEDIJI_O_NAMA: "Mediji o nama",
  MORE: "Više",
  LOAD_MORE: "Učitaj još",
  COUNSELING: "Savetovališta",
  TITLE_LOGIN: "Unesite Vaše pristupne parametre",
  PLACEHOLDER_EMAIL_REQUIRED: "Email (korisničko ime) *",
  PLACEHOLDER_PASSWORD: "Lozinka",
  BUTTON_LOGIN: "Prijava",
  BUTTON_LOGOUT: "Odjava",
  CHECKBOX_REMEMBER_PASSWORD: "zapamti lozinku",
  TITLE_MESSAGES_RESET_PASSWORD: "Greška prilikom reseta lozinke ",
  MESSAGES_EMAIL_FIELD: "Polje 'Email' je obavezno",
  FORGOTTEN_PASSWORD: "Zaboravljena lozinka?",
  DONT_HAVE_ACCOUNT: "Nemate nalog?",
  REGISTRATION: "Registrujte se",
  TITLE_REGISTRATION: "Napravite nalog za nastavak korišćenja aplikacije.",
  CHOSEN_NAME_REQUIRED: "Izabrano ime *",
  EMAIL_REQUIRED: "Email *",
  PASSWORD_REQUIRED: "Lozinka *",
  PASSWORD_CONFIRM_REQUIRED: "Ponovite lozinku *",
  PRIVACY_POLICY: "Klik za pregled politike privatnosti",
  PRIVACY_POLICY_ACCEPTED: "Prihvatam politiku privatnosti",
  BUTTON_REGISTRATION: "Prijava",
  MESSAGE_SUCCESS_REGISTRATION: "Uspešno ste se registrovali.",
  MESSAGE_ALL_FIELDS_REQUIRED: "Morate popuniti sva polja prilikom registracije.",
  MESSAGE_HAVE_TO_ACCEPT_PRIVACE_POLICY: "Morate prihvatiti politiku privatnosti kako biste nastavili.",
  WALKS: "Šetnje",
  ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Da li ste sigurni da želite da se odjavite?",
  YES: "Da",
  NO: "Ne",
  EVENT_TIME: "Vreme početka",
  EVENT_STATUS: "Status šetnje",
  EVENT_DIFFICULTY: "Težina",
  EVENT_DISTANCE: "Dužina",
  METER: "m",
  EVENT_REGISTERED_NUMBER: "Prijavljeno",
  EVENT_REGISTRATION: "Prijava",
  EVENT_SIGN_OUT: "Odjava",
  HAVE_TO_LOGIN_TO_CONTINUE: "Morate da se prijavite da biste nastavili.",
  REGISTRATION_SUCCESS: "Uspešno ste se prijavili.",
  SIGN_OUT_SUCCESS: "Uspešno ste se odjavili.",
  REGISTRATION_CODE: "Šifra prijave: ",
  MESSAGE_EVENT_UNREGISTRATION: "Da li ste sigurni da želite da se odjavite sa šetnje?",
  MESSAGE_EVENT_REGISTRATION: "Poštovani, uspešno ste se registrovali za izabranu šetnju. Vaš kod je: ",
  OK: "Ok",
  CANCEL: "Odustani",
  ACCEPT: "Potvrdi",
  EDIT_USER_PROFILE: "Izmena korisničkog profila",
  EMAIL: "Email",
  CHOSEN_NAME: "Izabrano ime",
  DATE_OF_BIRTH: "Datum rođenja",
  GENDER: "Pol",
  HEIGHT: "Visina",
  WEIGHT: "Težina",
  SAVE_CHANGES: "Sačuvaj izmene",
  CHOOSE: "Izaberite",
  MESSAGE_EDIT_PROFILE_SUCCESS: "Uspešno sačuvane izmene.",
  BUTTON_UPLOAD_IMAGE: "Učitaj sliku",
  BUTTON_CANCEL: "Odustani",
  BUTTON_SAVE: "Sačuvaj",
  MESSAGE_ERROR: "Došlo je do greške.",
  GENDER_MALE: "Muški",
  GENDER_FEMALE: "Ženski",
  CONFIRM_PASSWORD_REQUIRED: "Potvrda lozinke *",
  LABEL_PRIVACY_POLICY: "Politika privatnosti",
  PLACEHOLDER_GENDER: "Izaberite pol",
  EVENT_NOTE:
    "Zdravstveno stanje prijavljenih učesnika nije poznato organizatoru ove šetnje. Prijavom za određenu šetnju i odabirom intenziteta učesnik šetnje preuzima odgovornost da je odabrana fizička aktivnost odgovarajuća za njegovo zdravstveno stanje.",
  PUBLIC_TRANSPORT_LINE: "Linije prevoza",
  START_EVENT_PLACE: "Mesto okupljanja za početak šetnje",
  EVENT_GROUP: "Grupa",
  MESSAGE_EVENT_REGISTRATION_CODE: "Šifru je potrebno da saopštite vodiču prilikom okupljanja za početak šetnje.",
  EVENT_LIST_NO_DATA: "Trenutno nema organizovanih grupnih šetnji.",
  LABEL_REALIZE_DISTANCE: "Pređеni kilometri",
  LABEL_REALIZE: "Ostvareno",
  LABEL_NOT_REALIZE: "Do cilja",
  LABEL_JANUARY: "januar",
  LABEL_FEBRUARY: "februar",
  LABEL_MARCH: "mart",
  LABEL_APRIL: "april",
  LABEL_MAY: "maj",
  LABEL_JUNE: "jun",
  LABEL_JULY: "jul",
  LABEL_AUGUST: "avgust",
  LABEL_SEPTEMBER: "septembar",
  LABEL_OCTOBER: "oktobar",
  LABEL_NOVEMBER: "novembar",
  LABEL_DECEMBER: "decembar",
  LABEL_STATISTIC: "Statistika pređenih kilometara",
  DELIVERED_BY: "Delivered by",
  HELIANT: "Heliant",
};

export default Labels;

import axios from "axios";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
import Endpoint from "../system/Endpoint";
import Images from "../system/Images";
import Labels from "../system/Labels_sr_Latn_RS";
import { axiosConfiguration, DATE_TIME_FORMAT, getImage, handleAxiosCallError, INITIAL_LATITUDE, INITIAL_LONGITUDE, numberRepresentation } from "../system/Utils";
import { GMap } from "primereact/gmap";
import EventReadDto from "../../model/event/EventReadDto";
import { Card } from "primereact/card";
import { useMediaQuery } from "react-responsive";
import { Button } from "primereact/button";
import { AppContext } from "../system/Store";
import MessageType from "../system/MessageType";
import { confirmDialog } from "primereact/confirmdialog";
import EnumEventStatus from "../system/EnumEventStatus";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, ViberIcon, ViberShareButton, WhatsappIcon, WhatsappShareButton, LinkedinIcon, LinkedinShareButton } from "react-share";

export default function Event() {
  const { id } = useParams<any>();
  const [event, setEvent] = useState<EventReadDto>();
  const [loading, setLoading] = useState(true);
  const [overlays, setOverlays] = useState<any>([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { authData, showMessage } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();
  const [registrationCode, setRegistrationCode] = useState("");
  const [isVisibleRegistrationDialog, setIsVisibleRegistrationDialog] = useState(false);
  const [isVisibleUnregistrationDialog, setIsVisibleUnregistrationDialog] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registrationCode && isVisibleRegistrationDialog) {
      confirmDialog({
        message: `${Labels.MESSAGE_EVENT_REGISTRATION} ${registrationCode}.\n${Labels.MESSAGE_EVENT_REGISTRATION_CODE}`,
        acceptClassName: "ok-button",
        rejectClassName: "reject-button",
        acceptLabel: Labels.OK,
        className: "white-space-pre-wrap",
        accept: () => "",
      });
      setIsVisibleRegistrationDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationCode]);

  const fetchData = () => {
    const requestEvent = axios.get(`${Endpoint.WEB_EVENT_LIST}/${id}`, axiosConfiguration(authData?.token));
    axios
      .all([requestEvent])
      .then(
        axios.spread((responseEvent) => {
          setEvent(responseEvent.data.data);
          setIsDisabled(false);
          setLoading(false);
          let startMarker = new google.maps.Marker({
            position: {
              lat: responseEvent.data.data.startLatitude,
              lng: responseEvent.data.data.startLongitude,
            },
          });
          setOverlays([startMarker]);
        })
      )
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });

    if (authData?.token) {
      const requestEventRegistration = axios.get(`${Endpoint.WEB_EVENT_LIST}/${id}/registration/${authData?.currentUser.id}`, axiosConfiguration(authData?.token));
      axios
        .all([requestEventRegistration])
        .then(
          axios.spread((responseEventRegistration) => {
            if (responseEventRegistration.data.data) {
              setRegistrationCode(responseEventRegistration.data.data.code);
            }
            setIsDisabled(false);
            setLoading(false);
          })
        )
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
        });
    }
  };

  const register = () => {
    setIsVisibleRegistrationDialog(true);
    if (!authData.token) {
      showMessage(MessageType.WARNING, Labels.HAVE_TO_LOGIN_TO_CONTINUE, "");
      history.push("/login");
    } else {
      axios
        .post(`${Endpoint.WEB_EVENT_LIST}/${id}/register`, { event: { id: id }, user: { id: authData?.currentUser.id } }, axiosConfiguration(authData?.token))
        .then((response) => {
          fetchData();
        })
        .catch((error) => {
          handleAxiosCallError(showMessage, error);
          setIsDisabled(false);
        });
    }
  };

  const unregister = () => {
    axios
      .post(`${Endpoint.WEB_EVENT_LIST}/${id}/unregister`, { event: { id: id }, user: { id: authData?.currentUser.id } }, axiosConfiguration(authData?.token))
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
        setIsDisabled(false);
      });
  };

  useEffect(() => {
    if (isVisibleUnregistrationDialog) {
      confirmDialog({
        message: Labels.MESSAGE_EVENT_UNREGISTRATION,
        acceptLabel: Labels.ACCEPT,
        rejectLabel: Labels.CANCEL,
        icon: "pi pi-info-circle",
        accept: () => {
          setRegistrationCode("");
          unregister();
        },
        reject: () => {
          setIsDisabled(false);
        },
        onHide: () => {
          setIsDisabled(false);
        },
      });
    }
    setIsVisibleUnregistrationDialog(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleUnregistrationDialog]);

  return (
    <div className="layout-event">
      <div>
        <GridLoader size={50} color={"#2a918b"} loading={loading} css={"display: block; margin: 300px auto; borderColor: #2a918b"} />
        <div>
          <div id="home" className="slider">
            {!loading && <img className="d-block w-100" src={getImage(false, event?.headerImage, Images.EVENT_HEADER_DEFAULT)} alt="header" />}
          </div>
          <div id="article-ID" className={loading === true ? "" : "card"} style={{ overflow: "hidden" }}>
            {event && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <WhatsappShareButton title={event?.title} url={Endpoint.PROTOCOL_HOSTNAME + "/event/" + event?.id}>
                    <WhatsappIcon size={40}></WhatsappIcon>
                  </WhatsappShareButton>
                  <FacebookShareButton title={event?.title} url={Endpoint.PROTOCOL_HOSTNAME + "/event/" + event?.id}>
                    <FacebookIcon size={40}></FacebookIcon>
                  </FacebookShareButton>
                  <TwitterShareButton title={event?.title} url={Endpoint.PROTOCOL_HOSTNAME + "/event/" + event?.id}>
                    <TwitterIcon size={40}></TwitterIcon>
                  </TwitterShareButton>
                  <ViberShareButton title={event?.title} url={Endpoint.PROTOCOL_HOSTNAME + "/event/" + event?.id}>
                    <ViberIcon size={40}></ViberIcon>
                  </ViberShareButton>
                  <LinkedinShareButton title={event?.title} url={Endpoint.PROTOCOL_HOSTNAME + "/event/" + event?.id}>
                    <LinkedinIcon size={40}></LinkedinIcon>
                  </LinkedinShareButton>
                </div>
              </div>
            )}
            <hr style={{ marginTop: "0px" }} />
            <p className="mt-1 mb-1 title">{event?.title}</p>
            <hr />
            <div className="p-grid p-justify-between">
              <div className="p-col-12 p-md-3 p-lg-3">
                <Card className={isTabletOrMobile ? "small-card" : "card-with-padding"}>
                  <p className="card-title">{Labels.EVENT_STATUS}</p>
                  <p className={event?.eventStatus?.code === EnumEventStatus.CANCELED ? "card-orange-text" : "card-text"}>{event?.eventStatus?.name}</p>
                  <hr />
                  <p className="card-title">{Labels.EVENT_GROUP}</p>
                  <p className="card-text">{event?.eventDifficulty?.name}</p>
                  <hr />
                  <p className="card-title">{Labels.EVENT_DISTANCE}</p>
                  <p className="card-text">
                    {numberRepresentation(event?.totalDistanceMeters)} {Labels.METER}
                  </p>
                </Card>
              </div>
              <div className="p-col-12 p-md-3 p-lg-3">
                <Card className={isTabletOrMobile ? "small-card" : "card-with-padding"}>
                  <p className="card-title">{Labels.EVENT_TIME}</p>
                  <p className="card-text">{moment(event?.startTime).format(DATE_TIME_FORMAT)}</p>
                  <hr />
                  <p className="card-title">{Labels.EVENT_REGISTERED_NUMBER}</p>
                  <p className="card-text">
                    {event?.registrationList?.length}/{event?.maxPersons}
                  </p>
                  <hr />
                  <Button
                    className={!registrationCode ? "register-button" : "button-sign-out"}
                    disabled={isDisabled || event?.eventStatus.code !== EnumEventStatus.ACTIVE}
                    onClick={() => {
                      setIsDisabled(true);
                      registrationCode ? setIsVisibleUnregistrationDialog(true) : register();
                    }}
                  >
                    {registrationCode ? Labels.EVENT_SIGN_OUT : Labels.EVENT_REGISTRATION}
                  </Button>
                </Card>
              </div>
              <div className="p-col-12 p-md-6 p-lg-6">
                <Card className={isTabletOrMobile ? "small-card" : "card-with-padding"}>
                  {registrationCode !== "" && (
                    <div>
                      <p className="description">
                        {Labels.REGISTRATION_CODE} {registrationCode}
                      </p>
                      <hr />
                    </div>
                  )}
                  <p className="description">{event?.description}</p>
                  <hr />
                  <p className="description">{event?.note}</p>
                </Card>
              </div>
            </div>
            {event && (
              <div>
                <Card className={isTabletOrMobile ? "small-card" : "card-with-padding"}>
                  <div className="p-col-12 note-fix"> {Labels.EVENT_NOTE} </div>
                  <hr />
                  {event.publicTransportLines && (
                    <>
                      <p className="card-title">{Labels.PUBLIC_TRANSPORT_LINE}</p>
                      <p className="card-text">{event?.publicTransportLines}</p>
                      <hr />
                    </>
                  )}
                  {event.startLatitude && event.startLongitude && <div className="card-title">{Labels.START_EVENT_PLACE}</div>}
                  <GMap
                    overlays={overlays}
                    options={{
                      center: {
                        lat: event.startLatitude !== null ? event?.startLatitude : INITIAL_LATITUDE,
                        lng: event.startLongitude !== null ? event?.startLongitude : INITIAL_LONGITUDE,
                      },
                      zoom: 12,
                    }}
                    className="gmap"
                  />
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Style } from "./HomeStyle";
import Endpoint from "./system/Endpoint";
import { axiosConfig, getImage, MOST_VISITED_ARTICLE_SIZE, PAGE, dummyImageOPK, DATE_TIME_FORMAT, numberRepresentation, getMonthName } from "./system/Utils";
import Labels from "./system/Labels_sr_Latn_RS";
import GridLoader from "react-spinners/GridLoader";
import Images from "./system/Images";
import moment from "moment";
import { Card } from "primereact/card";
import EnumEventStatus from "./system/EnumEventStatus";
import EnumEventSport from "../model/event/EnumEventSport";
import DisplaySponsorList from "../components/sponsor/DisplaySponsorList";
import { Chart } from "primereact/chart";

export default function Home() {
  const history = useHistory();
  const [articleCarouselList, setArticleCarouselList] = useState([]);
  const [mostVisitedArticle, setMostVisitedArticle] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [loadingMostViewed, setLoadingMostViewed] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingCarusel, setLoadingCarusel] = useState(true);
  const [loadingMultimedia, setLoadingMultimedia] = useState(true);
  const [chartData, setChartData] = useState({});
  const [doughnutChartData, setDoughnutChartData] = useState({});
  const [activityRange, setActivityRange] = useState();
  const [multimediaList, setMultimediaList] = useState([]);
  const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    locale: "sr",
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };

  const lightOptions = {
    locale: "sr",
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  };

  useEffect(() => {
    axios
      .get(Endpoint.ARTICLE_ON_CAROUSEL_LIST, axiosConfig({ onCarousel: true }))
      .then((response) => {
        setArticleCarouselList(response.data.data);
        setLoadingCarusel(false);
      })
      .catch((error) => {});

    axios
      .get(Endpoint.MOST_VISITED_ARTICLE_LIST, axiosConfig({ page: PAGE, size: MOST_VISITED_ARTICLE_SIZE }))
      .then((response) => {
        setMostVisitedArticle(response.data.data);
        setLoadingMostViewed(false);
      })
      .catch((error) => {});

    axios
      .get(`${Endpoint.ARTICLE}/articleListByCode/MEDIJI_O_NAMA`, axiosConfig({ page: 0, size: 3 }))
      .then((response) => {
        setMultimediaList(response.data.data);
        setLoadingMultimedia(false);
      })
      .catch((error) => {
        setLoadingMultimedia(false);
      });
  }, []);

  useEffect(() => {
    const requestActivityRangeDetailList = axios.get(`${Endpoint.ACTIVITY_RANGE}/1/activityRangeDetail`, axiosConfig());
    const requestEventList = axios.get(Endpoint.HOME_PAGE, axiosConfig());
    axios
      .all([requestActivityRangeDetailList, requestEventList])
      .then(
        axios.spread((responseActivityRangeDetailList, responseEventList) => {
          setEventsList(responseEventList.data.data);
          let activityRangeConst;
          if (responseActivityRangeDetailList.data.data.length > 0) {
            activityRangeConst = responseActivityRangeDetailList.data.data[0].activityRange;
            setActivityRange(activityRangeConst);
          }
          setChartData({
            labels: responseActivityRangeDetailList.data.data.map((data) => {
              return getMonthName(data.month) + " " + data.year;
            }),
            datasets: [
              {
                label: Labels.LABEL_REALIZE_DISTANCE,
                backgroundColor: "#42A5F5",
                data: responseActivityRangeDetailList.data.data.map((data) => {
                  return Number.parseFloat(data.totalDistanceKilometers).toFixed(2);
                }),
              },
            ],
          });
          if (activityRangeConst?.goalKilometers) {
            let totalDistanceKilometersRealize = responseActivityRangeDetailList.data.data
              .map((data) => {
                return data.totalDistanceKilometers;
              })
              .reduce((a, b) => a + b, 0);
            setDoughnutChartData({
              labels: [Labels.LABEL_REALIZE, Labels.LABEL_NOT_REALIZE],
              datasets: [
                {
                  data: [
                    Number.parseFloat(totalDistanceKilometersRealize).toFixed(2),
                    activityRangeConst.goalKilometers > totalDistanceKilometersRealize
                      ? Number.parseFloat((activityRangeConst.goalKilometers - totalDistanceKilometersRealize).toString()).toFixed(2)
                      : 0,
                  ],
                  backgroundColor: ["#36A2EB", "#FF6384"],
                  hoverBackgroundColor: ["#36A2EB", "#FF6384"],
                },
              ],
            });
          }
          setLoadingEvents(false);
        })
      )
      .catch((error) => {
        setLoadingEvents(false);
      });
  }, []);

  return (
    <div className="layout-home">
      <GridLoader size={50} color={"#2a918b"} loading={loadingCarusel} css={Style.spinnerStyle} />
      {!loadingCarusel && (
        <div id="home" className="slider">
          <div id="main_slider" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators" style={{ marginBottom: 30 }}>
              {articleCarouselList.map((article, index) => {
                let activeClass = index === 0 ? "active" : "";
                return <li key={article.id} data-target="#main_slider" data-slide-to={index.toString()} className={activeClass}></li>;
              })}
            </ol>
            <div className="carousel-inner">
              {articleCarouselList.map((article, index) => {
                let activeClass = index === 0 ? "carousel-item active" : "carousel-item";
                return (
                  <div key={article.id} className={activeClass}>
                    <img className="d-block w-100" src={article.headerImage ? getImage(false, article.headerImage) : dummyImageOPK} alt="karusel" />
                    <button
                      className="on-hover-carousel"
                      onClick={() => {
                        history.push({
                          pathname: `/article/${article.id}`,
                          state: { article: article },
                        });
                      }}
                    >
                      <div className="ovarlay_slide_cont">
                        <img src={Images.LOGO_ON_DARK} className="ovarlay_slide_cont_logo" alt="logoOnDark" />
                        <div className="ovarlay_slide_cont_divider"></div>
                        <p>{article.title}</p>
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
            <a className="carousel-control-prev" href="#main_slider" role="button" data-slide="prev">
              <img className="carousel-control-next-img" src={Images.LEFT} alt="#" />
            </a>
            <a className="carousel-control-next" href="#main_slider" role="button" data-slide="next">
              <img className="carousel-control-next-img" src={Images.RIGHT} alt="#" />
            </a>
          </div>
        </div>
      )}

      {loadingEvents ? (
        <GridLoader size={50} color={"#2a918b"} loading={loadingEvents} css={Style.spinnerStyle} />
      ) : (
        <div id="service" className="hiw_section sponsorSection">
          {eventsList && eventsList.length > 0 ? (
            <div>
              <div className="row linear-gradient">
                <div className="col-12">
                  <p style={Style.sponsorTitle}>{Labels.WALKS}</p>
                </div>
              </div>
              <div>
                <div className="row justify-content-md-center px-5 layout-event">
                  {eventsList.map((event) => {
                    return (
                      <div key={event.id} className="col-md-3">
                        <button
                          className="card mt-2 mb-2"
                          style={event?.eventSport?.code === EnumEventSport.NORDIC_WALKING ? Style.nordicCard : Style.cursorPointer}
                          onClick={() => {
                            history.push({
                              pathname: `/event/${event.id}`,
                            });
                          }}
                        >
                          <div className="header-card-title">
                            <div className="p-col-12 card-title" style={event.eventSport.code === EnumEventSport.NORDIC_WALKING ? { color: "white" } : {}}>
                              {event?.title}
                            </div>
                          </div>
                          <img src={getImage(false, event.summaryImage, Images.EVENT_DEFAULT)} className="card-img-home card-img-top" alt="..." />
                          <div className="card-body p-col-12">
                            <div className="p-grid">
                              <div className="p-col-6">
                                <Card>
                                  <p className="card-title">{Labels.EVENT_TIME}</p>
                                  <p className="card-text">{moment(event?.startTime).format(DATE_TIME_FORMAT)}</p>
                                  <hr />
                                  <p className="card-title">{Labels.EVENT_GROUP}</p>
                                  <p className="card-text">{event?.eventDifficulty?.name}</p>
                                </Card>
                              </div>
                              <div className="p-col-6">
                                <Card>
                                  <p className="card-title">{Labels.EVENT_STATUS}</p>
                                  <p className={event?.eventStatus?.code === EnumEventStatus.CANCELED ? "card-orange-text" : "card-text"}>{event?.eventStatus?.name}</p>
                                  <hr />
                                  <p className="card-title">{Labels.EVENT_DISTANCE}</p>
                                  <p className="card-text">
                                    {numberRepresentation(event?.totalDistanceMeters)} {Labels.METER}
                                  </p>
                                </Card>
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      )}

      <div id="service" className="hiw_section sponsorSection">
        <div className="row linear-gradient">
          <div className="col-12">
            <p style={Style.sponsorTitle}>{Labels.MOST_VISITED}</p>
          </div>
        </div>

        <div className="row justify-content-md-center px-5 ">
          {loadingMostViewed ? (
            <GridLoader size={50} color={"#2a918b"} loading={loadingMostViewed} css={Style.spinnerStyle} />
          ) : (
            mostVisitedArticle.map((article) => {
              return (
                <div key={article.id} className="col-md-3">
                  <button
                    className="card mt-2 mb-2"
                    style={Style.cursorPointer}
                    onClick={() => {
                      history.push({
                        pathname: `/article/${article.id}`,
                        state: { article: article },
                      });
                    }}
                  >
                    <img src={getImage(false, article.summaryImage)} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title text-justify" style={Style.text}>
                        {article.title}
                      </h5>
                      <p className="card-text text-left" style={Style.texBlack}>
                        {article.summary}
                      </p>
                    </div>
                  </button>
                </div>
              );
            })
          )}
        </div>
      </div>

      <div id="about" className="about_section">
        {multimediaList.length !== 0 && (
          <div id="service" className="hiw_section sponsorSection">
            <div className="row linear-gradient">
              <div className="col-12">
                <p style={Style.sponsorTitle}>{Labels.MEDIJI_O_NAMA}</p>
              </div>
            </div>

            <div className="row justify-content-md-center px-5 ">
              {loadingMultimedia ? (
                <GridLoader size={50} color={"#2a918b"} loading={loadingMultimedia} css={Style.spinnerStyle} />
              ) : (
                multimediaList.map((article) => {
                  return (
                    <div key={article.id} className="col-md-3">
                      <button
                        className="card mt-2 mb-2"
                        style={Style.cursorPointer}
                        onClick={() => {
                          history.push({
                            pathname: `/article/${article.id}`,
                            state: { article: article },
                          });
                        }}
                      >
                        <img src={getImage(false, article.summaryImage)} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title text-justify" style={Style.text}>
                            {article.title}
                          </h5>
                          <p className="card-text text-left" style={Style.texBlack}>
                            {article.summary}
                          </p>
                        </div>
                      </button>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}

        {activityRange && (
          <div id="service" className="hiw_section sponsorSection">
            <div className="row linear-gradient">
              <div className="col-12">
                <p style={Style.sponsorTitle}>{activityRange?.name ? activityRange.name : Labels.LABEL_STATISTIC}</p>
              </div>
            </div>
            <div className="p-m-3 p-grid p-align-center p-justify-center">
              <div className="p-mr-6 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                <Chart type="bar" data={chartData} options={basicOptions} />
              </div>
              {activityRange && activityRange.goalKilometers && (
                <div className="p-xl-2 p-lg-2 p-md-2 p-sm-6">
                  <Chart type="doughnut" data={doughnutChartData} options={lightOptions} />
                </div>
              )}
            </div>
          </div>
        )}

        <div id="service" className="hiw_section sponsorSection">
          <div className="row linear-gradient">
            <div className="col-12">
              <p style={Style.sponsorTitle}>{Labels.PROJECT_PARTNERS}</p>
            </div>
          </div>
          <div className="row">
            <div className="layout-sponsor">
              <div className="panel-sponsor">
                <DisplaySponsorList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
